<template>
  <div class="home">
    <div class="swiper-box" v-if="swiperImgList.length > 0">
      <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide class="swiper-slide" v-for="(item, index) in swiperImgList" :key="index">
          <div class="swiper-item">
            <img :src="item.coverPic" @click="bannerClick(item)" data-swiper-parallax="0" data-swiper-parallax-duration="3000" data-swiper-parallax-opacity="0.5" data-swiper-parallax-scale="1.2" />
            <div class="video-play" @click.stop="videoPlay(item.videoUrl)" v-if="item.linkType === 4"></div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <transition name="video">
      <div class="video-popup" v-if="videoPopup">
        <div class="video-box">
          <video :src="videoUrl" class="video" controls ref="video" v-if="videoUrl" @canplay="canplay" controlsList="nodownload" oncontextmenu="return(false);"></video>
          <img src="../../static/img/home/close.png" class="close" @click="videoEnd">
        </div>
      </div>
    </transition>
    <div class="content" v-show="swiperImgList.length > 0">
      <div class="bulletin-board">
        <div class="ranking-box" :class="{'fixed-width':noticeInfo}" v-show="swiperImgList.length > 0">
          <div class="ranking-title">
            <c-title :width="14">
              <div class="text">{{$store.state.language ? rankObj.listTitle : rankObj.listTitleE}}</div>
            </c-title>
          </div>
          <div class="ranking-content">
            <div class="ranking-left">
              <div class="category-title">{{$t('category')}}</div>
              <div class="category-list">
                <div class="category-item" :class="{'category-sel':index === selTypeIndex}" v-for="(item,index) in dictTypeList" :key="index" @click="dictClick(item.dictName,index)">{{$store.state.language ? item.dictName : item.remarks}}</div>
              </div>
            </div>
            <div class="ranking-table" :class="$store.state.language ? '' : 'ranking-table-e'">
              <el-table :data="rankObj.detailList" :header-cell-style="{'background': 'transparent'}" height="322" ref="tableList">
                <el-table-column :label="$store.state.language ? '排 序' : 'Sort'" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column :prop="$store.state.language ? 'provinceName' : 'provinceNameE'" :label="$store.state.language ?'省 份' : 'Province'" align="center">
                </el-table-column>
                <el-table-column :prop="$store.state.language ? 'brandName' : 'brandNameE'" :label="$store.state.language ? '品牌名称' : 'Brand Name'" align="center">
                </el-table-column>
                <el-table-column prop="reputationNum" :label="$store.state.language ? '品牌声誉' : 'Reputation Num'" align="center">
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="see-ranking" @click="seeRanking($store.state.language ? rankObj.linkId : rankObj.linkIdE)" v-if="rankObj.detailList && rankObj.detailList.length > 0">{{$t('fullList')}}</div>
        </div>
        <div class="notice-box" v-if="noticeInfo">
          <div class="notice-title">
            <c-title :width="14">
              {{$store.state.language ? noticeInfo.noticeTitle : noticeInfo.noticeTitleE}}
            </c-title>
          </div>
          <div v-html="$store.state.language ? noticeInfo.noticeContent : noticeInfo.noticeContentE" class="notice-content"></div>
          <div class="sign-box">{{$store.state.language ? noticeInfo.signName : noticeInfo.signNameE}}</div>
          <div class="sign-box">{{noticeInfo.creatTime.slice(0,-9)}}</div>
          <div class="enroll" @click="goEnroll()" v-if="enrollBtnShow">{{$t('enrollUp')}}</div>
        </div>
      </div>
      <div class="gap-box">
        <img :src="getGapUrl(1)" class="gap-text" />
      </div>
      <!-- 地图 -->
      <div class="map-box">
        <div class="map-source">本地图数据来源于<a href="//datav.aliyun.com/portal/school/atlas/area_selector" target="_blank">DataV.GeoAtlas</a>，该版本数据更新于2021.5</div>
        <div class="map-left" ref="map"></div>
        <div class="map-right">
          <div class="province">
            <c-title :width="14">
              <div>{{ $store.state.language ? areaItemInfo.areaName : areaItemInfo.areaNameE}}</div>
            </c-title>
          </div>
          <div class="data-list">
            <!-- 插件地址: https://vuescrolljs.yvescoding.org/zh/guide/ -->
            <vue-scroll :ops="scrollOps" ref="scroll">
              <template v-for="(item, index) in areaItemInfo.tabBrandLandmarkList">
                <div class="data-item" :key="index" @click="infoClick(item)">
                  <div class="name-text">
                    {{ $store.state.language ? item.productName : item.englishName }}
                  </div>
                  <img v-if="item.cneurType == 1" src="../../static/img/home/cneur_type.png" class="cneur-type" />
                </div>
              </template>
            </vue-scroll>
          </div>
        </div>
      </div>
      <div class="gap-box">
        <img :src="getGapUrl(2)" class="gap-text" />
      </div>
      <!-- 品牌馆 -->
      <div class="brand-museum">
        <div class="brand-left-box">
          <template v-for="(item, index) in homeBrandList">
            <div class="left-item" :key="index" v-if="index % 3 === 0" @click="goBrandItem(item)" :class="{ 'left-item-bg2': index === 3 }">
              <img :src="item.coverImg" alt="" class="brand-img" />
              <div class="brand-text-box">
                <div class="brand-title">
                  <c-title :width="12" space="5">
                    <div class="brand-name">
                      {{ $store.state.language ? item.brandName : item.brandNameE }}
                    </div>
                  </c-title>
                </div>
                <div class="brand-text">
                  {{ $store.state.language ? item.brandProfile : item.brandProfileE }}
                </div>
                <div class="brand-more">
                  <more-icon></more-icon>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- 根据鼠标进入位置展现hover效果 https://github.com/NigelOToole/direction-reveal 插件地址-->
        <div class="direction-reveal brand-right-box">
          <template v-for="(item, index) in homeBrandList">
            <div class="direction-reveal__card right-item" :key="index" v-if="index % 3 !== 0" @click="goBrandItem(item)">
              <img :src="item.coverImg" class="brand-img" />
              <div class="direction-reveal__overlay direction-reveal__anim--enter" style="padding: initial" :style="{ background: bgColor[index] }">
                <div class="brand-text-box">
                  <div class="brand-title">
                    <c-title :width="12" space="5">
                      <div class="brand-name">
                        {{ $store.state.language ? item.brandName : item.brandNameE }}
                      </div>
                    </c-title>
                  </div>
                  <div class="brand-text">
                    {{ $store.state.language ? item.brandProfile : item.brandProfileE }}
                  </div>
                  <div class="brand-more">
                    <more-icon></more-icon>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="gap-box warehouse-gap">
        <img :src="getGapUrl(3)" class="gap-text" />
      </div>
      <!-- 形象仓库 -->
      <div class="warehouse-box">
        <div class="left" v-if="firstWare" @click="goIconItem(firstWare)">
          <div class="left-swiper">
            <swiper ref="mySwiper2" :options="swiperOption2">
              <template v-if="$store.state.language">
                <swiper-slide class="swiper-slide" v-for="(item, index) in firstWare.tabBrandWebPicList" :key="index">
                  <!-- <img :src="item.picUrl" alt="" /> -->
                  <div :style="
                      'width:100%;height:253px;background:url(' +
                      item.picUrl +
                      ') no-repeat center;background-size:auto 100%'
                    "></div>
                </swiper-slide>
              </template>
              <template v-else>
                <swiper-slide class="swiper-slide" v-for="(item, index) in firstWare.tabBrandWebPicList" :key="index">
                  <!-- <img :src="item.picUrl" alt="" /> -->
                  <div :style="
                      'width:100%;height:253px;background:url(' + item.picUrl + ') no-repeat center;background-size:auto 100%'
                    "></div>
                </swiper-slide>
              </template>
            </swiper>
          </div>
          <div class="title">
            <c-title :width="12">
              <div>{{ $store.state.language ? firstWare.brandName : firstWare.brandNameE }}</div>
            </c-title>
          </div>
          <div class="left-text">
            {{ $store.state.language ? firstWare.brandProfile : firstWare.brandProfileE }}
          </div>
          <div class="warehouse-left-more">
            <more-icon></more-icon>
          </div>
        </div>
        <div class="right">
          <template v-for="(item, index) in warehouse">
            <homeImageItem :info="item" :key="index"></homeImageItem>
          </template>
        </div>
      </div>
      <!-- 颜色展示 -->
      <!-- <div class="color-box" v-if="$store.state.language">
        <div class="color-list">
          <div class="color-item" v-for="(item, index) in colorList" :key="index">
            <img :src="getImgUrl(index)" class="color-icon" />
            <div class="color-text">{{ item }}</div>
          </div>
        </div>
        <div class="remake">·&ensp;以上色彩均源自《中国传统色》</div>
      </div> -->
      <div class="brief-introduction">
        <div class="intr-title">
          <c-title :width="14" :type="2">
            <div class="text">{{$t('htmlTitle')}}</div>
          </c-title>
        </div>
        <div v-html="introduceText.articleDetails" class="intr-content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBanner,
  homeBrand,
  homeWarehouse,
  mapData,
  homeNotice,
  homeIntroduce,
  homeDictList,
  homeRankingList
} from '@/api/home'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import cTitle from '@/components/cTitle/cTitle.vue'
import moreIcon from '@/components/moreIcon/moreIcon'
import DirectionReveal from 'direction-reveal'
import 'swiper/css/swiper.css'
import { geoCoordMap, provinceCHAndEn } from '../../static/map/area'
import homeImageItem from '@/components/home-image-item/home-image-item'

export default {
  name: 'home',
  components: {
    Swiper,
    SwiperSlide,
    cTitle,
    moreIcon,
    homeImageItem
  },
  data() {
    return {
      videoPopup: false,
      videoUrl: '',
      provinceList: [
        {
          areaCode: '65',
          areaName: '新疆',
          provincialCapital: '乌鲁木齐'
        },
        {
          areaCode: '54',
          areaName: '西藏',
          provincialCapital: '拉萨'
        },
        {
          areaCode: '63',
          areaName: '青海',
          provincialCapital: '西宁'
        },
        {
          areaCode: '62',
          areaName: '甘肃',
          provincialCapital: '兰州'
        },
        {
          areaCode: '15',
          areaName: '内蒙古',
          provincialCapital: '呼和浩特'
        },
        {
          areaCode: '23',
          areaName: '黑龙江',
          provincialCapital: '哈尔滨'
        },
        {
          areaCode: '22',
          areaName: '吉林',
          provincialCapital: '长春'
        },
        {
          areaCode: '21',
          areaName: '辽宁',
          provincialCapital: '沈阳'
        },
        {
          areaCode: '13',
          areaName: '河北',
          provincialCapital: '石家庄'
        },
        {
          areaCode: '11',
          areaName: '北京',
          provincialCapital: '北京'
        },
        {
          areaCode: '14',
          areaName: '山西',
          provincialCapital: '太原'
        },
        {
          areaCode: '61',
          areaName: '陕西',
          provincialCapital: '西安'
        },
        {
          areaCode: '64',
          areaName: '宁夏',
          provincialCapital: '银川'
        },
        {
          areaCode: '51',
          areaName: '四川',
          provincialCapital: '成都'
        },
        {
          areaCode: '53',
          areaName: '云南',
          provincialCapital: '昆明'
        },
        {
          areaCode: '52',
          areaName: '贵州',
          provincialCapital: '贵阳'
        },
        {
          areaCode: '50',
          areaName: '重庆',
          provincialCapital: '重庆'
        },
        {
          areaCode: '42',
          areaName: '湖北',
          provincialCapital: '武汉'
        },
        {
          areaCode: '41',
          areaName: '河南',
          provincialCapital: '郑州'
        },
        {
          areaCode: '37',
          areaName: '山东',
          provincialCapital: '济南'
        },
        {
          areaCode: '34',
          areaName: '安徽',
          provincialCapital: '合肥'
        },
        {
          areaCode: '32',
          areaName: '江苏',
          provincialCapital: '南京'
        },
        {
          areaCode: '43',
          areaName: '湖南',
          provincialCapital: '长沙'
        },
        {
          areaCode: '45',
          areaName: '广西',
          provincialCapital: '南宁'
        },
        {
          areaCode: '44',
          areaName: '广东',
          provincialCapital: '广州'
        },
        {
          areaCode: '46',
          areaName: '海南',
          provincialCapital: '海口'
        },
        {
          areaCode: '36',
          areaName: '江西',
          provincialCapital: '南昌'
        },
        {
          areaCode: '35',
          areaName: '福建',
          provincialCapital: '福州'
        },
        {
          areaCode: '33',
          areaName: '浙江',
          provincialCapital: '杭州'
        },
        {
          areaCode: '71',
          areaName: '台湾',
          provincialCapital: '台北'
        },
        {
          areaCode: '31',
          areaName: '上海',
          provincialCapital: '上海'
        },
        {
          areaCode: '12',
          areaName: '天津',
          provincialCapital: '天津'
        }
      ],
      activeAreaCode: 37, // 默认选中的省份的areaCode
      hoverIndex: -1, // 鼠标hover选中的省份下标
      allAreaData: [],
      areaItemInfo: {},
      colorList: [
        '朱湛',
        '胭脂虫',
        '牙绯',
        '柘黄',
        '杏黄',
        '栀子',
        '紫磨金',
        '鹿皮褐',
        '椒房',
        '木槿'
      ],
      introduceText: {},
      noticeInfo: null,
      warehouse: [],
      firstWare: null,
      homeBrandList: [],
      swiperImgList: [],
      swiperOption: {
        parallax: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        //显示分页,并且自定义样式
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'customs',
          clickable: true,
          bulletActiveClass: 'customs-active',
          renderBullet(index, className) {
            return '<div class="' + className + '"></div>'
          }
        },
        //自动轮播
        autoplay: {
          delay: 4000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false
        },
        //开启循环模式
        loop: false
      },
      swiperOption2: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: true
      },
      bgColor: [
        '',
        'rgba(162,74,135,0.75)',
        'rgba(188,80,41,0.75)',
        '',
        'rgba(211,109,47,0.75)',
        'rgba(158,27,42,0.75)'
      ],
      scrollOps: {
        bar: {
          onlyShowBarOnScroll: false,
          background: '#c1c1c1',
          minSize: 0.05
        }
      },
      rankObj: {
        listTitle: '',
        listTitleE: '',
        detailList: []
      },
      dictTypeList: [],
      selTypeIndex: 0,
      categoryName: '',
      enrollBtnShow: false
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  created() {
    this.enrollBtnShow = this.checkTime('2022/05/18', '2022/06/18')
    this.getBanner()
    this.getDictList()
    this.getNotice()
    setTimeout(() => {
      this.getMapData()
      this.homeBrand()
      this.homeWarehouse()
      this.getHomeIntroduce()
    }, 350)
  },
  mounted() {
    // 初始化地图的背景
    this.mapbg = new Image()
    this.borderImg = new Image()
    let src = require('@/static/img/map/mapbg.jpg')
    let src2 = require('@/static/img/map/border_img.jpg')
    this.mapbg.src = src
    this.borderImg.src = src2
    setTimeout(() => {
      this.myChart()
    }, 300)
  },
  methods: {
    // 报名时间区间
    checkTime(startTime, endTime) {
      var curDate = new Date()
      startTime = new Date(startTime)
      endTime = new Date(endTime)
      if (curDate >= startTime && curDate <= endTime) {
        return true
      }
      return false
    },
    // 获取品类列表
    getDictList() {
      homeDictList({
        dictTypeCode: 'PRODUCT_CATEGORY'
      }).then((res) => {
        if (res.code === 10000) {
          this.dictTypeList = res.data
          this.categoryName = this.dictTypeList[0].dictName
          this.getRankingList()
        }
      })
    },
    // 品类切换
    dictClick(name, index) {
      this.categoryName = name
      this.selTypeIndex = index
      this.$nextTick(() => {
        this.$refs.tableList.bodyWrapper.scrollTop = 0
      })
      this.getRankingList()
    },
    // 获取榜单信息
    getRankingList() {
      homeRankingList({
        categoryName: this.categoryName
      }).then((res) => {
        if (res.code === 10000) {
          let data = res.data
          if (data) {
            this.rankObj = data
          } else {
            this.rankObj = {
              listTitle: '中国地理标志农产品品牌声誉榜单',
              listTitleE: 'China AGI Brand Ranking',
              detailList: []
            }
          }
        }
      })
    },
    // 前往报名页
    goEnroll() {
      this.$router.push({
        path: '/brandDynamic/enroll'
      })
    },
    seeRanking(id) {
      this.$router.push({
        path: `/academicField/articleDetails?id=${id}&columnCode=010401`
      })
    },
    // 绘制地图
    myChart() {
      let nameMap = this.$store.state.language ? {} : provinceCHAndEn
      let mapData = [
        {
          name: '北京市',
          value: 14.45,
          areaCode: '11'
        },
        {
          name: '天津市',
          value: 14.23,
          areaCode: '12'
        },
        {
          name: '上海市',
          value: 19.39,
          areaCode: '31'
        },
        {
          name: '重庆市',
          value: 20.4,
          areaCode: '50'
        },
        {
          name: '河北省',
          value: 105.6,
          areaCode: '13'
        },
        {
          name: '河南省',
          value: 66.29,
          areaCode: '41'
        },
        {
          name: '云南省',
          value: 26.88,
          areaCode: '53'
        },
        {
          name: '辽宁省',
          value: 60.51,
          areaCode: '21'
        },
        {
          name: '黑龙江省',
          value: 40.96,
          areaCode: '23'
        },
        {
          name: '湖南省',
          value: 36.57,
          areaCode: '43'
        },
        {
          name: '安徽省',
          value: 49,
          areaCode: '34'
        },
        {
          name: '山东省',
          value: 115.86,
          selected: true,
          areaCode: '37'
        },
        {
          name: '新疆维吾尔自治区',
          value: 38.84,
          areaCode: '65'
        },
        {
          name: '江苏省',
          value: 90.72,
          areaCode: '32'
        },
        {
          name: '浙江省',
          value: 43.2,
          areaCode: '33'
        },
        {
          name: '江西省',
          value: 35.54,
          areaCode: '36'
        },
        {
          name: '湖北省',
          value: 37.67,
          areaCode: '42'
        },
        {
          name: '广西壮族自治区',
          value: 34.56,
          areaCode: '45'
        },
        {
          name: '甘肃省',
          value: 21.25,
          areaCode: '62'
        },
        {
          name: '山西省',
          value: 52.1,
          areaCode: '14'
        },
        {
          name: '内蒙古自治区',
          value: 50.55,
          areaCode: '15'
        },
        {
          name: '陕西省',
          value: 33.98,
          areaCode: '61'
        },
        {
          name: '吉林省',
          value: 25.54,
          areaCode: '22'
        },
        {
          name: '福建省',
          value: 27.72,
          areaCode: '35'
        },
        {
          name: '贵州省',
          value: 35.97,
          areaCode: '52'
        },
        {
          name: '广东省',
          value: 82.97,
          areaCode: '44'
        },
        {
          name: '青海省',
          value: 7.23,
          areaCode: '63'
        },
        {
          name: '西藏自治区',
          value: 3.02,
          areaCode: '54'
        },
        {
          name: '四川省',
          value: 45.76,
          areaCode: '51'
        },
        {
          name: '宁夏回族自治区',
          value: 16.17,
          areaCode: '64'
        },
        {
          name: '海南省',
          value: 8.01,
          areaCode: '46'
        },
        {
          name: '台湾省',
          value: 8.01,
          areaCode: '71'
        },
        {
          name: '香港特别行政区',
          value: 8.01,
          areaCode: '81'
        },
        {
          name: '澳门特别行政区',
          value: 8.01,
          areaCode: '82'
        }
      ]
      let myChart = this.$echarts.init(this.$refs.map)
      // 绘制图表
      myChart.setOption({
        geo: {
          map: 'china',
          zoom: 1.2,
          itemStyle: {
            areaColor: {
              image: this.mapbg,
              // repeat: 'no-repeat'
            }
          }
        },
        series: [
          {
            type: 'map',
            map: 'china',
            zoom: 1.2,
            // geoIndex: 0,
            label: {
              show: true,
              offset: [0, 0],
              color: 'rgba(255,255,255,0.55)'
            },
            emphasis: {
              label: {
                // show: true,
                color: 'rgba(255,255,255,0.55)'
              },
              itemStyle: {
                areaColor: '#e77215'
              }
            },
            select: {
              label: {
                // show: true,
                color: 'rgba(255,255,255,0.55)'
              },
              itemStyle: {
                areaColor: '#e77215'
              }
            },
            itemStyle: {
              areaColor: 'rgba(255,255,255,0)',
              // borderColor:'#fff',
              borderColor: {
                image: this.borderImg,
                repeat: 'repeat'
              },
              borderWidth: 1.3
            },
            nameMap,
            data: mapData
          },
          {
            type: 'scatter',
            rippleEffect: {},
            coordinateSystem: 'geo',
            zlevel: 2,
            label: {
              show: false,
              formatter: '{b}',
              color: '#fff'
            },
            emphasis: {
              scale: false
            },
            symbolSize: 6,
            itemStyle: {
              color: '#fff'
            },
            data: this.provinceList.map(function (dataItem) {
              return {
                name: dataItem.provincialCapital,
                value: geoCoordMap[dataItem.provincialCapital]
              }
            })
          }
        ]
      })
      let that = this
      myChart.on('click', function (params) {
        if (params.componentSubType === 'map' && params.data) {
          that.activeAreaCode = params.data.areaCode
          that.getMapData()
          that.$refs.scroll.scrollTo({ y: 0 }, 200)
        }
      })
    },
    // 视频弹出
    videoPlay(url) {
      this.videoUrl = url
      this.videoPopup = true
      this.swiper.autoplay.stop()
    },
    // 关闭视频
    videoEnd() {
      this.videoPopup = false
      this.swiper.autoplay.start()
      this.videoUrl = ''
      this.$store.commit('playMusic')
    },
    // 视频加载完成能播放
    canplay() {
      let player = this.$refs.video
      player.play()
      this.$store.commit('pauseMusic')
    },
    // banner轮播图
    getBanner() {
      getBanner().then((res) => {
        if (res.code === 10000) {
          this.swiperImgList = res.data
          this.show = true
        }
      })
    },
    // 公告
    getNotice() {
      homeNotice().then((res) => {
        if (res.code === 10000) {
          let data = res.data
          // let urlId = this.$store.state.language ? data.linkId : data.linkIdE
          // let str = `<a href="#/brandDynamic/dynamicItem?id=${urlId}" rel="noopener">【${
          //   this.$store.state.language ? '详情' : 'details'
          // }】</a>`
          // data.noticeContent = data.noticeContent + str
          // data.noticeContentE = data.noticeContentE + str
          this.noticeInfo = data
        }
      })
    },
    // 首页介绍
    getHomeIntroduce() {
      homeIntroduce().then((res) => {
        if (res.code === 10000) {
          // console.log(res.data)
          if (res.data) {
            res.data.articleDetails = res.data.articleDetails.replace(
              /<img/g,
              '<img style="max-width:100%;height: auto;"'
            )
            this.introduceText = res.data
          }
        }
      })
    },
    // 首页精选品牌
    homeBrand() {
      homeBrand({
        limit: 6
      }).then((res) => {
        if (res.code === 10000) {
          this.homeBrandList = res.data
          // 初始化插件 根据鼠标进入位置，展现从不同方向 展现 hover 效果
          setTimeout(() => {
            const directionRevealDefault = DirectionReveal({
              selector: '.direction-reveal',
              itemSelector: '.direction-reveal__card',
              animationName: 'slide',
              animationPostfixEnter: 'enter',
              animationPostfixLeave: 'leave',
              enableTouch: true,
              touchThreshold: 250
            })
          })
        }
      })
    },
    // 首页形象仓库
    homeWarehouse() {
      homeWarehouse({
        limit: 7
      }).then((res) => {
        if (res.code === 10000) {
          // console.log(res.data)
          let data = res.data
          this.warehouse = data.slice(1, data.length)
          this.firstWare = data[0]
        }
      })
    },
    // 地图数据
    getMapData() {
      mapData({
        areaCode: this.activeAreaCode
      }).then((res) => {
        if (res.code === 10000) {
          // console.log(res.data)
          // this.allAreaData = res.data
          // this.selAeraInfo()
          this.areaItemInfo = res.data
        }
      })
    },
    getImgUrl(index) {
      return require('@/static/img/home/color' + (index + 1) + '.png')
    },
    getGapUrl(index) {
      if (this.$store.state.language) {
        return require('@/static/img/home/gap_' + index + '_ch.png')
      }
      return require('@/static/img/home/gap_' + index + '_en.png')
    },
    // 省份点击
    aeraActive(item) {
      this.activeAreaCode = item.areaCode
      this.getMapData()
    },
    // 香港澳门点击
    special(areaCode) {
      this.activeAreaCode = areaCode
      this.getMapData()
    },
    // 提取选中省份的数据
    selAeraInfo() {
      this.allAreaData.forEach((item) => {
        if (item.areaCode == this.activeAreaCode) {
          // console.log(item)
          this.areaItemInfo = item
        }
      })
    },
    // 省份的数据点击跳转
    infoClick(item) {
      if (item.brandId) {
        this.$router.push({
          path: '/brand/brandItem',
          query: {
            brandId: item.brandId,
            name: item.productName,
            nameE: item.englishName
          }
        })
      } else {
        let link = item.websiteUrl
        if (link.indexOf('http') === -1) {
          link = 'http://' + link
        }
        window.open(link)
      }
    },
    // 品牌基础详情
    goBrandItem(item) {
      this.$router.push({
        path: '/brand/brandItem',
        query: {
          brandId: item.brandId,
          name: item.brandName,
          nameE: item.brandNameE
        }
      })
    },
    // 形象仓库详情
    goIconItem(item, index) {
      if (index) {
        item.checked = false
        this.$set(this.warehouse, index, item)
      }
      this.$router.push({
        path: '/iconStoreHouse/iconItem',
        query: {
          warehouseId: item.warehouseId,
          name: item.brandName,
          nameE: item.brandNameE
        }
      })
    },
    // banner点击
    bannerClick(item) {
      if (item.linkType === 2) {
        this.$router.push({
          path: '/brand/brandItem',
          query: {
            brandId: item.linkId,
            name: item.linkName
          }
        })
      } else if (item.linkType === 5) {
        if (this.enrollBtnShow) {
          this.$router.push({
            path: item.videoUrl
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import 'node_modules/direction-reveal/src/styles/direction-reveal.scss';

.home {
  min-height: 100%;

  .swiper-box {
    width: 100%;
    position: relative;

    .swiper-container {
      width: 100%;
      height: 100%;
      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 6%;
        .customs {
          width: 47px;
          height: 21px;
          margin: 0 6px;
          background: url('../../static/img/home/customs.png') no-repeat center;
          background-size: 100% 100%;
          cursor: pointer;
        }
        .customs-active {
          background: url('../../static/img/home/customs_active.png') no-repeat
            center;
          background-size: 100% 100%;
        }
      }
      .swiper-item {
        position: relative;

        img {
          width: 100%;
          height: auto;
          background: rgba(175, 27, 32, 0.8);
        }
        .video-play {
          position: absolute;
          bottom: 10%;
          left: 10%;
          width: 128px;
          height: 128px;
          background: url('../../static/img/home/v_paly.png') no-repeat;
          background-size: 100% 100%;
          z-index: 9;
          cursor: pointer;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  .video-popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-box {
      width: 70%;
      position: relative;
      .video {
        width: 100%;
        height: auto;
      }
      .close {
        width: 26px;
        height: 26px;
        position: absolute;
        top: 0;
        right: -40px;
        cursor: pointer;
      }
    }
  }
  // video动画效果
  .video-enter,
  .video-leave-to {
    opacity: 0;
    transform: scale(0);
    transform-origin: center center;
  }
  .video-enter-active,
  .video-leave-active {
    transition: all 0.5s;
  }

  .bulletin-board {
    display: flex;
    justify-content: space-between;
    margin: 22px auto 0;
    min-height: 380px;

    .ranking-box {
      flex: auto;
      background: url('../../static/img/home/ranking_bg.png') no-repeat center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      padding: 0 0 20px 0;
      .ranking-title {
        font-size: 20px;
        line-height: 20px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0 0 0;
      }
    }
    .fixed-width {
      width: 830px;
    }
    .ranking-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ranking-left {
      width: 106px;
      min-height: 236px;
      background: url('../../static/img/home/ranking_left.png') no-repeat;
      background-size: 100% 100%;
      margin: 22px 20px 0 0;
      // margin: 22px 20px 0 118px;
      padding: 0 0 10px 0;
      font-size: 12px;
      color: #fff;
      // padding: 36px 0 0 0;
      .category-title {
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 2px;
        font-weight: bold;
        text-align: center;
        margin: 20px 0 10px 0;
      }
      .category-list {
        font-size: 16px;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        .category-item {
          display: flex;
          align-self: center;
          margin: 5px 0;
          color: rgba($color: #fff, $alpha: 0.6);
          cursor: pointer;
          text-align: center;
        }
        .category-sel {
          position: relative;
          color: #fff;
          &::before {
            content: '';
            width: 4px;
            height: 10px;
            background: url('../../static/img/home/category_sel.png') no-repeat
              left center;
            background-size: 100% 100%;
            position: absolute;
            left: -10px;
            top: 50%;
            margin: -5px 0 0 0;
          }
        }
      }
      // > div {
      //   margin: 0 0 5px 0;
      //   text-align: center;
      // }
    }
    .ranking-table {
      // min-width: 470px;
      width: 56.8%;
      background-color: transparent !important;
      .el-table {
        color: #fff;
        font-size: 16px !important;
        background-color: transparent !important;
        &::before {
          height: 0;
        }
        .cell {
          line-height: 26px !important;
          white-space: nowrap;
          text-overflow: initial;
        }
        thead {
          color: #fff;
        }
        th {
          padding: 18px 0 6px 0;
          font-size: 14px;
        }
        th.is-leaf {
          border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
        }
        tr {
          background-color: transparent !important;
        }
        td {
          padding: initial;
          border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
        }
      }
      .el-table--enable-row-hover .el-table__body tr:hover > td {
        background: transparent;
      }
    }
    .ranking-table-e {
      width: 74%;
      .el-table {
        .cell {
          line-height: 32px !important;
          white-space: nowrap;
          text-overflow: initial;
        }
      }
    }
    .notice-box {
      flex: 0 0 auto;
      width: 288px;
      min-height: 278px;
      background: url('../../static/img/home/notice_bg.png') no-repeat center;
      background-size: cover;
      // padding: 0 0 40px 0;
      margin: 0 0 0 24px;
      display: flex;
      flex-direction: column;
      .notice-title {
        font-size: 18px;
        line-height: 20px;
        color: #fff;
        padding: 32px 20px 30px;
        display: flex;
        justify-content: center;
      }
      .notice-content {
        padding: 0 38px;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        // margin: 0 0 13px 0;
        text-align: justify;
        text-indent: 24px;
        word-break: break-all;
      }
    }
    .see-ranking {
      background: url('../../static/img/layout/link_icon.png') no-repeat left
        center;
      background-size: 25px 8px;
      padding: 0 0 0 30px;
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      align-self: flex-end;
      margin: 16px 112px 0 0;
      cursor: pointer;
    }
    .sign-box {
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      color: #fff;
      // padding: 0 22px 0 0;
      margin: 10px 0 0 0;
    }
    .enroll {
      width: 120px;
      height: 35px;
      margin: 20px 0 0 0;
      align-self: center;
      cursor: pointer;
      flex: 0 0 auto;
      background: url('../../static/img/home/enroll.png') no-repeat;
      background-size: 100% 100%;
      color: #d3905b;
      text-align: center;
      font-size: 14px;
      line-height: 35px;
      font-weight: bold;
    }
  }

  .content {
    width: 1140px;
    margin: 0 auto;
    padding: 0 0 20px 0;
  }
  .gap-box {
    height: 40px;
    width: 100%;
    background: url('../../static/img/home/gap-bg.png') no-repeat center;
    background-size: 100% 100%;
    margin: 22px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .gap-text {
      height: 33px;
    }
  }
  .map-box {
    display: flex;
    justify-content: space-between;
    height: 800px;
    position: relative;
    .map-source {
      position: absolute;
      bottom: 2px;
      left: 4px;
      color: rgba($color: #fff, $alpha: 0.55);
      // color: #fff;
      font-size: 12px;
      line-height: 18px;
      z-index: 9;
    }
    .map-left {
      width: 830px;
      height: 800px;
      background: url('../../static/img/map/map_box_bg.jpg') no-repeat center;
      background-size: 100% 100%;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -khtml-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
    .map-right {
      width: 289px;
      height: 100%;
      background: url('../../static/img/home/map-right.png') no-repeat center;
      background-size: 100% 100%;
      position: relative;
      .province {
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        // height: 31px;
        display: flex;
        align-items: center;
        padding: 42px 0 0 24px;
        font-weight: bold;
      }
      .data-list {
        width: 252px;
        height: 650px;
        margin: 24px 0 0 19px;
        .data-item {
          height: 56px;
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          border-bottom: 1px solid #a3341a;
          padding: 0 0 0 26px;
          background: url('../../static/img/home/item-icon.png') no-repeat left
            17px center;
          background-size: 5px 8px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 54px;
          .name-text {
            max-width: 190px;
            height: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .cneur-type {
            width: 32px;
            height: 32px;
          }
        }
      }
      .map-more {
        position: absolute;
        right: 23px;
        bottom: 52px;
      }
    }
  }
  .brand-museum {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .brand-left-box {
      width: 498px;
      margin: 0 22px 0 0;
      .left-item {
        width: 498px;
        height: 349px;
        background: url('../../static/img/home/brand-bg01.png') no-repeat center;
        background-size: 498px 349px;
        display: flex;
        position: relative;
        margin: 0 0 20px 0;
      }
      .left-item-bg2 {
        background: url('../../static/img/home/brand-bg02.png') no-repeat center;
        background-size: 498px 349px;
      }
    }
    .brand-right-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .right-item {
        margin: 0 0 20px 0;
      }
    }
    .brand-text-box {
      color: #fff;
      width: 154px;
      margin: 36px 0 0 24px;
      .brand-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
      }
      .brand-name {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: center;
        font-weight: bold;
        // flex: 1;
        line-height: 22px;
      }
      .brand-text {
        width: 154px;
        margin: 20px 0 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 9;
        font-size: 12px;
        line-height: 18px;
        // text-indent: 25px;
        background: url('../../static/img/home/indent-icon.png') no-repeat left
          top 4px;
        background-size: 11px 10px;
      }
    }
    .brand-img {
      width: 300px;
      height: 349px;
      display: block;
    }
    .brand-more {
      position: absolute;
      right: 24px;
      bottom: 32px;
    }
  }
  .warehouse-gap {
    margin: 0 auto 20px;
  }
  .warehouse-box {
    display: flex;
    flex-wrap: wrap;
    .left {
      width: 336px;
      height: 483px;
      margin: 0 21px 0 0;
      background: url('../../static/img/home/warehouse-left-bg.png') no-repeat
        center;
      background-size: 100% 100%;
      color: #ffffff;
      position: relative;
      .left-swiper {
        height: 253px;
        overflow: hidden;
        img {
          // width: 100%;
          height: 253px;
          object-fit: none;
        }
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        margin: 16px 25px;
        font-weight: bold;
      }
      .left-text {
        padding: 0 25px;
        font-size: 12px;
        line-height: 18px;
        // text-indent: 20px;
        background: url('../../static/img/home/indent-icon.png') no-repeat left
          24px top 4px;
        background-size: 11px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
      }
      .warehouse-left-more {
        position: absolute;
        right: 25px;
        bottom: 18px;
      }
    }
    .right {
      flex: 1;
      height: 483px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: hidden;
    }
  }
  .color-box {
    .color-list {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0 0;
      .color-item {
        width: 80px;
        height: 20px;
        position: relative;
        .color-icon {
          width: 100%;
          height: 100%;
        }
        .color-text {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
    .remake {
      color: #1b1b1b;
      font-size: 12px;
      margin: 11px 0 0 0;
    }
  }
  .brief-introduction {
    min-height: 278px;
    background: url('../../static/img/home/introduction_bg.png') no-repeat
      center;
    background-size: cover;
    padding: 0 0 50px 0;
    margin: 20px 0 0 0;
    .intr-title {
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #bf494d;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 44px 0 40px 0;
    }
    .intr-content {
      padding: 0 68px;
    }
  }
}
</style>
