<template>
  <div class="home-image-item" @click="goIconItem(itemInfo)">
    <div style="overflow: hidden">
      <div class="info-img-box" @mouseenter="mouseenter(itemInfo)" @mouseleave="mouseLeave(itemInfo)" :class="{ 'item-active': itemInfo.checked }">
        <img :src="itemInfo.logo" alt="" class="info-img" />
      </div>
    </div>
    <div class="info-content">
      <div class="info-title">{{ $store.state.language ? itemInfo.brandName : itemInfo.brandNameE }}</div>
      <div class="info-text" :class="{'t-indent':$store.state.language}"> {{ $store.state.language ? itemInfo.imageExplain : itemInfo.imageExplainE }} </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    itemInfo() {
      this.info.imageExplain = this.info.imageExplain.replace('品牌标志', '')
      this.info.imageExplain = this.info.imageExplain.replace('LOGO', '')
      this.info.imageExplainE = this.info.imageExplainE.replace('Logo', '')
      //   this.info.checked = false
      return this.info
    }
  },
  data() {
    return {}
  },

  methods: {
    //  鼠标移入图片放大
    mouseenter(item) {
      //   item.checked = true
      this.$set(item, 'checked', true)
    },
    // 鼠标移出图片
    mouseLeave(item) {
      //   item.checked = false
      this.$set(item, 'checked', false)
    },
    // 形象仓库详情
    goIconItem(item) {
      this.$set(item, 'checked', false)
      this.$router.push({
        path: '/iconStoreHouse/iconItem',
        query: {
          warehouseId: item.warehouseId,
          name: item.brandName,
          nameE: item.brandNameE
        }
      })
    }
  }
}
</script>

<style lang="scss">
.home-image-item {
  width: 246px;
  height: 231px;
  background: url('../../static/img/home/brand-info-bg.png') no-repeat left
    bottom #f1f1f1;
  background-size: 100% 88px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 22px 0;
  display: flex;
  flex-direction: column;
  .info-img-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s cubic-bezier(0, 1, 1, 1);
    .info-img {
      // width: 143px;
      height: 143px;
    }
  }
  .item-active {
    transform: scale(1.2);
    transform-origin: center center;
  }
  .info-content {
    height: 88px;
    .info-title {
      padding: 12px 15px 10px;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: bold;
    }
    .info-text {
      padding: 0 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      // text-indent: 20px;
      background: url('../../static/img/home/indent-icon.png') no-repeat left
        15px top 5px;
      background-size: 11px 10px;
    }
    .t-indent {
      text-indent: 28px;
    }
  }
}
</style>